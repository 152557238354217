.header {
    background-image: url(./assets/bg.jpg);
    min-height: 300px;
    display: flex;
    .header_contents {
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        width: 100%;
        min-height: 100%;
    }
    nav {
        padding: 20px 20px;
    }
}
.contents {
    justify-content: center ;
    align-items: center ;
    display:flex ;
    .form {
        max-width: 500px;
        width: 90%;
        background-color: rgb(255, 255, 255) ;
        min-height: 300px ;
        position: relative;
        top: -120px ;
        border-radius: 3px ;
        box-shadow: 0px 5px 12px -8px rgba(0,0,0,.5);
        &_header{
           padding:  18px 20px ;
           border-bottom: solid 1px rgb(241, 241, 241) ;
        }
        &_contents{
            padding:  4px 20px ;
        }
    }
}
