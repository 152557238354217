.wrapper {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 300px;
    position: relative;
    .header {
        padding: 4px 20px;
        text-align: center;
        position: relative;
    }
    .content {
        padding: 4px 20px;
        text-align: center;
    }
    .close_button {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:all ease .2s ;
        &:hover,&:focus,&:active{
            opacity: .6 ;
        }
    }
}
.call_button{
    display: inline-block;
    background: #f3f3f3;
    color: #1d1d1d;
    font-weight: bold;
    padding: 8px 20px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px -1px rgba(0 ,0 ,0 , .8);
    cursor: pointer;
    text-decoration: none !important;
    transition: all ease .2s ;
    &:hover,&:focus,&:active{
        box-shadow: 0px 2px 3px -1px rgba(0 ,0 ,0 , .8);
        color: #000 ;
    }
    &_icon{
    display:inline-block ;
    margin-right: 8px ;
    position: relative;
    top: -1px ;
    }
    &_label{
        font-weight: 500 ;
    }
}
