body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(243, 243, 243) ;
  background-image: url(./assets/bg.png);
}

.react-tel-input{
    input{
      width: 100% !important;
    }
}
h1,h2,h3,h4,h5,h6{
  margin : 0 ;
  padding: 0;
}

.react-tel-input{
  input.form-control{
    border-color: #efefef;
    height: 50px ;
    border-radius: 2px ;
    box-shadow: none !important ;
  }
  .flag-dropdown{
    background-color: transparent !important ;
    border: none !important;
  }
  .selected-flag{
    padding-left: 12px !important ;
  }
}